.App {
  text-align: center;
  font-family: "Poppins", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.navbar {
  font-size: 20px;
  font-weight: 500;
}

.navbar li a {
  margin-left: 50px;
  margin-right: 50px;
  color: #fff;
  border-bottom: 3px solid transparent;
}

.navbar li a:hover,
.navbar li a:active,
.navbar li a:focus {
  color: #fff;
  border-bottom: 3px solid #fff;
}

.offcanvas {
  background-color: #000;
}

.offcanvas .offcanvas-header button {
  box-shadow: none;
  outline: none;
  border: none;
}

.landingSec {
  height: 100vh;
}

.imageBack {
  background-image: url("../public/images/landing.png");
  width: 100%;
  height: 1000px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: right center;
}

.landingSec {
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 200px;
}

.landingSec h1 {
  color: #fff;
  font-weight: 800;
  font-size: 65px;
}

.landingSec p {
  color: #fff;
  font-size: 18px;
  width: 80%;
  opacity: 0.8;
}

.quote {
  padding-left: 50px;
  padding-right: 50px;
  background-color: #640b8e;
  height: 300px;
}

.quote h1 {
  color: #fff;
  font-weight: 600;
  font-size: 60px;
  font-style: italic;
}

.demoSec {
  background-color: #640b8e;
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 150px;
  padding-bottom: 150px;
}

.demoSec h1 {
  color: white;
  font-size: 70px;
  font-weight: 900;
}

.demoSec p {
  color: white;
  font-size: 23px;
  opacity: 0.9;
}

input,
input:focus {
  background-color: transparent !important;
  outline: none;
  box-shadow: none !important;
  border: 1px solid #fff;
  border-radius: 0px !important;
  height: 60px;
}

input::placeholder {
  color: #fff !important;
}

.btnSolid {
  background-color: #640b8e;
  box-shadow: none;
  outline: none;
  height: 60px;
  color: #fff;
  border-radius: 0px;
  width: 150px;
}

.btnSolid:hover {
  border: 1px solid #fff;
  background-color: transparent !important;
}

.btnDownload {
  background-color: #640b8e;
  box-shadow: none;
  outline: none;
  height: 50px;
  color: #fff;
  border-radius: 0px;
  width: 150px;
  font-size: 18px;
  font-weight: 400;
}

.btnDownload:hover {
  border: 1px solid #fff;
  background-color: transparent !important;
  color: #fff;
}

.workingSec {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 150px;
  padding-bottom: 150px;
}

.workingSec h1 {
  font-size: 70px;
  font-weight: 900;
}

.workingSec p {
  color: black;
  font-size: 23px;
  opacity: 0.7;
  line-height: 25px;
}

.workingSec .workingSec-con {
  margin-top: 50px;
  background-color: #f8f9fa;
  border-radius: 25px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.downloadSec {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 50px;
  padding-bottom: 50px;
  background-color: #f8f9fa;
}

.downloadSec h1 {
  font-size: 70px;
  font-weight: 900;
}

.downloadSec .highlight {
  color: #640b8e !important;
}

.reviewSec {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 100px;
  padding-bottom: 100px;
}

.reviewSec h1 {
  font-size: 70px;
  font-weight: 900;
}

.reviewSec h1 span {
  color: #640b8e;
}

.review-item-container {
  padding: 50px;
  height: 500px;
  width: 800px;
  background-color: #640b8e;
  border-radius: 20px;
  clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 100%, 0 100%);
}

.review-item {
  left: -48px;
  top: -48px;
  position: relative;
  padding: 50px;
  width: 796px;
  height: 496px;
  background: #f8f9fa;
  border-radius: 20px;
  clip-path: polygon(0 0, 80% 0, 100% 20%, 100% 100%, 0 100%);
}

.review-item .user-img {
  height: 120px;
  height: 120px;
  border-radius: 100%;
}

.review-item h3 {
  font-size: 30px;
  font-weight: 800;
}

.review-item .rating {
  color: #640b8e;
  font-weight: 600;
}

.review-item p {
  font-size: 20px;
  opacity: 0.8;
}

.reviewSec .nav-btn {
  height: 70px;
  width: 70px;
  border-radius: 100%;
  background-color: #640b8e;
  margin: 10px;
  color: #fff;
  font-size: 25px;
  padding-top: 15px;
}

.reviewSec .nav-btn:hover {
  background-color: #1c1c8c;
  transition: 0.5s;
  cursor: pointer;
}

.downloadPlay img {
  max-width: 200px;
}

.footer {
  padding-left: 100px;
  padding-right: 100px;
  background-color: #000;
  color: #fff;
}

.footer h5 {
  margin-bottom: 30px !important;
}

.footer .desc {
  color: #fff;
  opacity: 0.6;
}

.footer .nav-link {
  text-decoration: none;
  color: #fff;
  opacity: 0.6;
  margin-top: 5px;
  margin-bottom: 5px;
  font-size: 15px;
}

.docs {
  font-family: "Poppins", sans-serif;
}

.docs h1 {
  font-size: 60px;
  font-weight: 900;
  margin-bottom: 50px;
}

.docs h2 {
  font-size: 25px;
  font-weight: 800;
  margin-top: 45px;
  margin-bottom: 30px;
  text-transform: capitalize !important;
}

.docs p {
  font-size: 18px;
  opacity: 0.9;
}

@media screen and (max-width: 1750px) {
  .review-item-container {
    height: 400px;
    width: 700px;
  }
  
  .review-item {
    width: 696px;
    height: 396px;
  }
}

@media screen and (max-width: 1520px) {
  .review-item-container {
    margin-left: auto;
    margin-right: auto;
  }
}

@media screen and (max-width: 1440px) {
  .navbar .navbar-toggler {
    outline: none;
    box-shadow: none;
    border: none;
  }
}

@media screen and (max-width: 1199px) {
  .offcanvas .offcanvas-body {
    text-align: center;
  }

  .offcanvas .offcanvas-body li a,
  .offcanvas .offcanvas-body li a:hover,
  .offcanvas .offcanvas-body li a:active,
  .offcanvas .offcanvas-body li a:focus {
    border: none;
  }

  .offcanvas .offcanvas-body li a {
    font-size: 40px;
    font-weight: 800;
  }

  .offcanvas .offcanvas-body .btnDownload {
    width: 80%;
    margin-top: 50px;
    height: 60px;
  }
}

@media screen and (max-width: 1024px) {
  .landingSec h1 {
    font-size: 60px;
  }

  .quote h1 {
    font-size: 50px;
  }
}

@media screen and (max-width: 768px) {
  .imageBack {
    background-position: 60% center;
  }

  .landingSec,
  .quote,
  .downloadSec,
  .demoSec,
  .workingSec,
  .reviewSec,
  .footer {
    padding-left: 20px;
    padding-right: 20px;
  }

  .landingSec h1 {
    text-align: center;
    font-size: 55px;
  }

  .landingSec p {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }

  .landingSec .downloadPlay {
    justify-content: center;
  }

  .quote h1 {
    font-size: 45px;
  }

  .downloadSec h1,
  .demoSec h1,
  .workingSec h1,
  .reviewSec h1 {
    font-size: 55px;
  }

  .workingSec, .demoSec {
    padding-top: 80px;
    padding-bottom: 80px;
  }

  .review-item-container {
    height: 300px;
    width: 500px;
  }
  
  .review-item {
    padding: 10px;
    width: 496px;
    height: 296px;
  }

  .review-item .user-img {
    height: 80px;
    height: 80px;
  }
  
  .review-item h3 {
    font-size: 25px;
  }
  
  .review-item p {
    font-size: 15px;
  }
  
  .reviewSec .nav-btn {
    height: 50px;
    width: 50px;
    margin: 10px;
    font-size: 20px;
    padding-top: 8px;
  }
  
}

@media screen and (max-width: 560px) {
  .landingSec h1 {
    font-size: 45px;
  }

  .landingSec p {
    font-size: 13px;
  }

  .quote h1 {
    font-size: 35px;
  }

  .downloadSec h1,
  .demoSec h1,
  .workingSec h1,
  .reviewSec h1 {
    font-size: 45px;
  }

  .reviewSec{
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .review-item-container {
    height: 320px;
    width: 350px;
  }
  
  .review-item {
    left: -48px;
    top: -48px;
    padding: 10px;
    width: 346px;
    height: 316px;
  }

  .downloadPlay img {
    max-width: 150px;
    margin-left: 5px;
    margin-right: 5px;
  }

  .footer h5 {
    margin-top: 30px;
    margin-bottom: 10px !important;
  }

  .footer p {
    font-size: 10px;
  }
}